import React from 'react';
import './App.css';
import { HomeBodySectionArray } from './content/HomeBodySectionArray';
import HomeBodySection from './components/HomeBodySection';

function Home() {
    return (
        <div>
            {HomeBodySectionArray.map((id, i) => {
                return (
                    <HomeBodySection
                        key = {i} 
                        HomeBodySectionArray = {HomeBodySectionArray}
                        SectionIndex = {i}
                    />                    
                )
            })}
        </div>
    )
  };

  export default Home;