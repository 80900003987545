import React from 'react';
import './TopBar.css';
import DropdownMenuItem from './DropdownMenuItem';

const DropdownMenu = ({ parentId, dropdownArray }) => {
    if (parentId === 'apps'){
        return (
            <div id='TopBarDropdownMiddle' className='DropDownOuter DropDownOuterMiddle'>
                <div className='DropDownColumn'>
                    {dropdownArray.map((key, i) => {
                        return (
                            <DropdownMenuItem
                                key={dropdownArray[i].id+ i}
                                id={dropdownArray[i].id}
                                parentId={parentId}
                                hidden={dropdownArray[i].hidden}
                                dropdown={dropdownArray[i].dropdown}
                                app={dropdownArray[i].app}
                                img={dropdownArray[i].img}
                                text={dropdownArray[i].text}
                                imgUrl={dropdownArray[i].imgUrl}
                                linkUrl={dropdownArray[i].linkUrl}
                            />
                        )
                    })}
                </div>
            </div>
        )
    } else {
        return(
            <div id='TopBarDropdownRight' className='DropDownOuter DropDownOuterRight'>
                <div className='DropDownColumn'>
                    {dropdownArray.map((key, i) => {
                        return (
                            <DropdownMenuItem
                                key={dropdownArray[i].id+ i}
                                id={dropdownArray[i].id}
                                parentId={parentId}
                                hidden={dropdownArray[i].hidden}
                                dropdown={dropdownArray[i].dropdown}
                                app={dropdownArray[i].app}
                                img={dropdownArray[i].img}
                                text={dropdownArray[i].text}
                                imgUrl={dropdownArray[i].imgUrl}
                                linkUrl={dropdownArray[i].linkUrl}
                            />
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default DropdownMenu;