import React from 'react';
import './HomeBodySection.css';
import HBSContentRowItem from './HBSContentRowItem';

const HBSContentRow = ({ id, hidden, columnNo, form, button, title, link, img, text, option, imgUrl, linkUrl }) => {
    if (columnNo === 1) {
        return (
            <HBSContentRowItem
                id={id}
                hidden={hidden}
                columnNo={columnNo}
                form={form}
                button={button}
                title={title}
                link={link}
                img={img}
                text={text}
                option={option}
                imgUrl={imgUrl}
                linkUrl={linkUrl}
            />                    
        )
    } else {
        return(
            <div className='TwoColumnRow HBSContentRow'>
                <div className='TwoColumnItem'>
                    {(() => {
                        return (
                            <HBSContentRowItem
                                id={id}
                                hidden={hidden[0]}
                                columnNo={columnNo}
                                form={form[0]}
                                button={button[0]}
                                title={title[0]}
                                link={link[0]}
                                img={img[0]}
                                text={text[0]}
                                option={option[0]}
                                imgUrl={imgUrl[0]}
                                linkUrl={linkUrl[0]}
                            />                    
                        )
                    })()}
                </div>

                <div className='TwoColumnItem'>
                    {(() => {
                        return (
                            <HBSContentRowItem
                                id={id}
                                hidden={hidden[1]}
                                columnNo={columnNo}
                                form={form[1]}
                                button={button[1]}
                                title={title[1]}
                                link={link[1]}
                                img={img[1]}
                                text={text[1]}
                                option={option[1]}
                                imgUrl={imgUrl[1]}
                                linkUrl={linkUrl[1]}
                            />                    
                        )
                    })()}
                </div>
            </div>
        )
    }
}
 
export default HBSContentRow;