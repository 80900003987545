import React, { useState } from 'react';
import './Page.css';
import PageRowItem from './PageRowItem';

function PageRow ( {id, parentAppPage, hidden, columnNo, form, button, title, link, img, text, option, imgUrl, linkUrl} ) {

    function getOS() {
        var userAgent = navigator.userAgent || navigator.vendor
    
        if (/Chrome/i.test(userAgent)){
            return "web";
        } else if (/android/i.test(userAgent)) {
            return "android";
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        } else {
            return "web";
        }
    }

    var deviceOS = 'web'

    if (parentAppPage){
        deviceOS = getOS();
    }

    const [hideIOS, setHideIOS] = useState(1)
    const [hideAndroid, setHideAndroid] = useState(1)

    function qrHide(itemId){
        if ((itemId === 'iOS') && (!hideIOS)) {
            setHideIOS(1);
        }
        if ((itemId === 'android') && (!hideAndroid)) {
            setHideAndroid(1);
        }
    }

    function qrDisplay(itemId){
        if ((itemId === 'iOS') && (hideIOS)) {
            setHideIOS(0);
        }
        if ((itemId === 'android') && (hideAndroid)) {
            setHideAndroid(0);
        }
    }

    if (columnNo === 1){
        return (
            <PageRowItem
                id={id}
                hidden={hidden}
                form={form}
                button={button}
                title={title}
                link={link}
                img={img}
                text={text}
                option={option}
                imgUrl={imgUrl}
                linkUrl={linkUrl}
            />                    
        )
    } else {
        if (parentAppPage){
            if (id === deviceOS) {
                return (
                    <div className='TwoColumnRow'>
                        <div className='TwoColumnItem'>
                            <PageRowItem
                                id={id}
                                hidden={hidden[0]}
                                form={form[0]}
                                button={button[0]}
                                title={title[0]}
                                link={2}
                                img={img[0]}
                                text={text[0]}
                                option={option[0]}
                                imgUrl={imgUrl[0]}
                                linkUrl={linkUrl[0]}
                            />                    
                        </div>

                        <div className='TwoColumnItem'>
                            <PageRowItem
                                id={id}
                                hidden={1}
                                form={form[1]}
                                button={button[1]}
                                title={title[1]}
                                link={link[1]}
                                img={img[1]}
                                text={text[1]}
                                option={option[1]}
                                imgUrl={imgUrl[1]}
                                linkUrl={linkUrl[1]}
                            />                    
                        </div>
                    </div>
                )
            } else if ((id === 'iOS') || (id === 'android')){
                return(
                    <div onMouseLeave={() => qrHide(id)} className='TwoColumnRow'>
                        {(() => {
                            if ((deviceOS === 'iOS') || (deviceOS === 'android')) {
                                return (
                                    <div onClick={() => qrDisplay(id)} onMouseEnter={() => qrDisplay(id)} className='TwoColumnItem'>
                                        <PageRowItem
                                            id={id}
                                            hidden={hidden[0]}
                                            form={form[0]}
                                            button={button[0]}
                                            title={title[0]}
                                            link={0}
                                            img={img[0]}
                                            text={text[0]}
                                            option={option[0]}
                                            imgUrl={imgUrl[0]}
                                            linkUrl={linkUrl[0]}
                                        />                    
                                    </div>
                                )
                            } else {
                                return (
                                    <div onClick={() => qrDisplay(id)} className='TwoColumnItem'>
                                        <PageRowItem
                                            id={id}
                                            hidden={hidden[0]}
                                            form={form[0]}
                                            button={button[0]}
                                            title={title[0]}
                                            link={0}
                                            img={img[0]}
                                            text={text[0]}
                                            option={option[0]}
                                            imgUrl={imgUrl[0]}
                                            linkUrl={linkUrl[0]}
                                        />                    
                                    </div>
                                )
                            }
                        })()}
        
                        <div className='TwoColumnItem'>
                            {(() => {
                                if (id === 'iOS') {
                                    return (
                                        <PageRowItem
                                            id={id}
                                            hidden={hideIOS}
                                            form={form[1]}
                                            button={button[1]}
                                            title={title[1]}
                                            link={link[1]}
                                            img={img[1]}
                                            text={text[1]}
                                            option={option[1]}
                                            imgUrl={imgUrl[1]}
                                            linkUrl={linkUrl[1]}
                                        />                    
                                    )
                                } else if (id === 'android') {
                                    return (
                                        <PageRowItem
                                            id={id}
                                            hidden={hideAndroid}
                                            form={form[1]}
                                            button={button[1]}
                                            title={title[1]}
                                            link={link[1]}
                                            img={img[1]}
                                            text={text[1]}
                                            option={option[1]}
                                            imgUrl={imgUrl[1]}
                                            linkUrl={linkUrl[1]}
                                        />                    
                                    )
                                } else {
                                    return null
                                }
                            })()}
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className='TwoColumnRow'>
                        <div className='TwoColumnItem'>
                            <PageRowItem
                                id={id}
                                hidden={hidden[0]}
                                form={form[0]}
                                button={button[0]}
                                title={title[0]}
                                link={link[0]}
                                img={img[0]}
                                text={text[0]}
                                option={option[0]}
                                imgUrl={imgUrl[0]}
                                linkUrl={linkUrl[0]}
                            />                    
                        </div>
        
                        <div className='TwoColumnItem'>
                            <PageRowItem
                                id={id}
                                hidden={hidden[1]}
                                form={form[1]}
                                button={button[1]}
                                title={title[1]}
                                link={link[1]}
                                img={img[1]}
                                text={text[1]}
                                option={option[1]}
                                imgUrl={imgUrl[1]}
                                linkUrl={linkUrl[1]}
                            />                    
                        </div>
                    </div>                    
                )
            }
        } else {
            return(
                <div className='TwoColumnRow'>
                    <div className='TwoColumnItem'>
                        <PageRowItem
                            id={id}
                            hidden={hidden[0]}
                            form={form[0]}
                            button={button[0]}
                            title={title[0]}
                            link={link[0]}
                            img={img[0]}
                            text={text[0]}
                            option={option[0]}
                            imgUrl={imgUrl[0]}
                            linkUrl={linkUrl[0]}
                        />                    
                    </div>
    
                    <div className='TwoColumnItem'>
                        <PageRowItem
                            id={id}
                            hidden={hidden[1]}
                            form={form[1]}
                            button={button[1]}
                            title={title[1]}
                            link={link[1]}
                            img={img[1]}
                            text={text[1]}
                            option={option[1]}
                            imgUrl={imgUrl[1]}
                            linkUrl={linkUrl[1]}
                        />
                    </div>
                </div>
            )
        }
    }
}

export default PageRow;