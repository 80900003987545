import React from 'react';
import TopBarItem from "./TopBarItem";
import './TopBar.css';

const TopBar = ({ TopBarArray }) => {
    return ( 
        <div className='TopBar'>
            {TopBarArray.map((id, i) => {
                return (
                    <TopBarItem
                        key={TopBarArray[i].id + i}
                        id={TopBarArray[i].id}
                        hidden={TopBarArray[i].hidden}
                        dropdown={TopBarArray[i].dropdown}
                        web={TopBarArray[i].web}
                        mobile={TopBarArray[i].mobile}
                        app={TopBarArray[i].app}
                        img={TopBarArray[i].img}
                        text={TopBarArray[i].text}
                        imgUrl={TopBarArray[i].imgUrl}
                        linkUrl={TopBarArray[i].linkUrl}
                        dropdownArray={TopBarArray}
                    />
                )
            })}
        </div>
    )
};
 
export default TopBar;