import dillLogo from '../assets/Logo.svg';

export const BottomBarArray = [
    [
        {
            id: 'dillGuestApp',
            textORimg: 'text',
            text: 'dill Guest App',
            img: 'NA',
            link: 'dillGuest',
            hidden: 0
        },
        {
            id: 'dillManagerApp',
            textORimg: 'text',
            text: 'dill Manager App',
            img: 'NA',
            link: 'dillManager',
            hidden: 0
        },
        {
            id: 'dillServerApp',
            textORimg: 'text',
            text: 'dill Server App',
            img: 'NA',
            link: 'dillServer',
            hidden: 0
        },
        {
            id: 'about',
            textORimg: 'text',
            text: 'About dill',
            img: 'NA',
            link: 'about',
            hidden: 0
        },
        {
            id: 'events',
            textORimg: 'text',
            text: 'Events',
            img: 'NA',
            link: 'events',
            hidden: 0
        },
        {
            id: 'home',
            textORimg: 'img',
            text: 'NA',
            img: dillLogo,
            link: '/',
            hidden: 0
        }
    ]
    ,
    [
        {
            id: 'bookVisit',
            textORimg: 'text',
            text: 'Book Visit',
            img: 'NA',
            link: 'bookVisit',
            hidden: 0
        },
        {
            id: 'support',
            textORimg: 'text',
            text: 'Support',
            img: 'NA',
            link: 'support',
            hidden: 0
        },
        {
            id: 'investors',
            textORimg: 'text',
            text: 'Investors',
            img: 'NA',
            link: 'investors',
            hidden: 0
        },
        {
            id: 'partners',
            textORimg: 'text',
            text: 'Partners',
            img: 'NA',
            link: 'partners',
            hidden: 0
        },
        {
            id: 'contact',
            textORimg: 'text',
            text: 'Contact',
            img: 'NA',
            link: 'contact',
            hidden: 0
        },
        {
            id: 'careers',
            textORimg: 'text',
            text: 'Careers',
            img: 'NA',
            link: 'careers',
            hidden: 0
        } 
    ],    
    [
        {
            id: 'privacypolicy',
            textORimg: 'text',
            text: 'Privacy Policy',
            img: 'NA',
            link: 'privacyPolicy',
            hidden: 0
        },
        {
            id: 'termsofuse',
            textORimg: 'text',
            text: 'Terms of Use',
            img: 'NA',
            link: 'termsOfUse',
            hidden: 0
        },
        {
            id: 'legal',
            textORimg: 'text',
            text: 'Legal',
            img: 'NA',
            link: 'legal',
            hidden: 0
        }        
    ],
    [
        {
            id: 'inclusiondiversity',
            textORimg: 'text',
            text: 'Inclusion and Diversity',
            img: 'NA',
            link: 'inclusionDiversity',
            hidden: 0
        },
        {
            id: 'environment',
            textORimg: 'text',
            text: 'Environment',
            img: 'NA',
            link: 'environment',
            hidden: 0
        },
        {
            id: 'privacy',
            textORimg: 'text',
            text: 'Privacy',
            img: 'NA',
            link: 'privacy',
            hidden: 0
        },
        {
            id: 'ethicscompliance',
            textORimg: 'text',
            text: 'Ethics and Compliance',
            img: 'NA',
            link: 'ethicsCompliance',
            hidden: 0
        }
    ]
];