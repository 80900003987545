import React from 'react';
import './App.css';
import './components/TopBar.css';
import './components/Page.css';
import './components/BottomBar.css';

import TopBar from './components/TopBar';
import {TopBarArray} from './content/TopBarArray';
import BottomBar from './components/BottomBar';
import {BottomBarArray} from './content/BottomBarArray';
import Page from './components/Page';
import { PagesContentArray } from './content/PagesContentArray';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from "./Home";
import ErrorPage from "./ErrorPage";

function App() {

  let currentPage = window.location.href;
  window.addEventListener("click", () => {
    if (currentPage !== window.location.href) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      currentPage = window.location.href;
  }
  })

  return (
    <BrowserRouter>
      <div className="App">
        <div className='TopBarOuter'>
          <TopBar TopBarArray={TopBarArray} />
        </div>
        <div>
          <Routes>
            <Route path='/:page' element={<Page PagesContentArray={PagesContentArray}/>} />
            <Route index element={<Home />} />
            <Route path='/' exact element={<Home />} />
            <Route path='/*' element={<ErrorPage />} />
          </Routes>
        </div>
        <div>
          <BottomBar BottomBarArray={BottomBarArray}/>
        </div>
      </div>
    </BrowserRouter>
  )}

export default App;