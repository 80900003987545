import React, { useState } from 'react';
import HBSContentRow from "./HBSContentRow";
import './HomeBodySection.css';
import { BkgdStyleArray } from '../content/BkgdStyleArray';

const HomeBodySection = ({HomeBodySectionArray, SectionIndex}) => {
    const [isMobile, setIsMobile] = useState(0);

    function resizeRowHeight(){
        var rowHeight = ((window.innerHeight) * 3 / 4) - 112;
        if (rowHeight < 360) {
            rowHeight = 360;
        }
        var maxImgWidth = ((window.innerWidth) * 3 / 4) - 32;
        var maxImgHeight = rowHeight - 128;
        var imgSize = 0;
        if (maxImgHeight > maxImgWidth){
            imgSize = maxImgWidth;
        } else {
            imgSize = maxImgHeight;
        }
        document.documentElement.style.setProperty('--rowHeight', rowHeight + "pt");
        document.documentElement.style.setProperty('--imgSize', imgSize + "pt");
    }

    function updateIsMobile(){
        if (isMobile) {
            if (window.innerWidth > 808) {
                setIsMobile(0);
            }
        } else {
            if (window.innerWidth < 808) {
                setIsMobile(1);
            }
        }
    }

    function resizeRefresh(){
        resizeRowHeight();
        updateIsMobile();
    }

    resizeRefresh();
    window.addEventListener('resize', resizeRefresh);

    function styleHBS(){
        var len = BkgdStyleArray.length;
        var styleIndex = Math.floor(Math.random() * 100) % len;
        return BkgdStyleArray[styleIndex];
    }

    if (!HomeBodySectionArray[SectionIndex].hidden) {
        var HBSPos = ''
        if (isMobile){
            HBSPos = 'full';
        } else {
            HBSPos = HomeBodySectionArray[SectionIndex].leftFullRight;
        }
        
        if (HBSPos === 'full') {
            return (
                <div className='HBSOuter HBSOuterFull' style={styleHBS()}>
                    {/* <img className='HBSbckgrndImg' src={HomeBodySectionArray[SectionIndex].bkgrndImg} /> */}
                    <div className='HBSContentTitle'>
                        {HomeBodySectionArray[SectionIndex].title}
                    </div>
                    <div className='HBSContent'>
                        {HomeBodySectionArray[SectionIndex].contentArray.map((id, i) => {
                            return (
                                <HBSContentRow
                                    key={HomeBodySectionArray[SectionIndex].contentArray[i].id + i}
                                    id={HomeBodySectionArray[SectionIndex].contentArray[i].id}
                                    hidden={HomeBodySectionArray[SectionIndex].contentArray[i].hidden}
                                    columnNo={HomeBodySectionArray[SectionIndex].contentArray[i].columnNo}
                                    form={HomeBodySectionArray[SectionIndex].contentArray[i].form}
                                    button={HomeBodySectionArray[SectionIndex].contentArray[i].button}
                                    title={HomeBodySectionArray[SectionIndex].contentArray[i].title}
                                    link={HomeBodySectionArray[SectionIndex].contentArray[i].link}
                                    img={HomeBodySectionArray[SectionIndex].contentArray[i].img}
                                    text={HomeBodySectionArray[SectionIndex].contentArray[i].text}
                                    option={HomeBodySectionArray[SectionIndex].contentArray[i].option}
                                    imgUrl={HomeBodySectionArray[SectionIndex].contentArray[i].imgUrl}
                                    linkUrl={HomeBodySectionArray[SectionIndex].contentArray[i].linkUrl}
                                />                    
                            )
                            }
                        )}
                    </div>
                </div>
            );           
        } else if (HBSPos === 'left') {
            return (
                <div className='HBSOuter HBSOuterLeft' style={styleHBS()}>
                    <div className='HBSContentTitle'>
                        {HomeBodySectionArray[SectionIndex].title}
                    </div>
                    <div className='HBSContent'>
                        {HomeBodySectionArray[SectionIndex].contentArray.map((id, i) => {
                            return (
                                <HBSContentRow
                                    key={HomeBodySectionArray[SectionIndex].contentArray[i].id + i}
                                    id={HomeBodySectionArray[SectionIndex].contentArray[i].id}
                                    hidden={HomeBodySectionArray[SectionIndex].contentArray[i].hidden}
                                    columnNo={HomeBodySectionArray[SectionIndex].contentArray[i].columnNo}
                                    form={HomeBodySectionArray[SectionIndex].contentArray[i].form}
                                    button={HomeBodySectionArray[SectionIndex].contentArray[i].button}
                                    title={HomeBodySectionArray[SectionIndex].contentArray[i].title}
                                    link={HomeBodySectionArray[SectionIndex].contentArray[i].link}
                                    img={HomeBodySectionArray[SectionIndex].contentArray[i].img}
                                    text={HomeBodySectionArray[SectionIndex].contentArray[i].text}
                                    option={HomeBodySectionArray[SectionIndex].contentArray[i].option}
                                    imgUrl={HomeBodySectionArray[SectionIndex].contentArray[i].imgUrl}
                                    linkUrl={HomeBodySectionArray[SectionIndex].contentArray[i].linkUrl}
                                />                    
                            )
                            }
                        )}
                    </div>
                </div>
            );     
        } else if (HBSPos === 'right') {
            return (
                <div className='HBSOuter HBSOuterRight' style={styleHBS()}>
                    <div className='HBSContentTitle'>
                        {HomeBodySectionArray[SectionIndex].title}
                    </div>
                    <div className='HBSContent'>
                        {HomeBodySectionArray[SectionIndex].contentArray.map((id, i) => {
                            return (
                                <HBSContentRow
                                    key={HomeBodySectionArray[SectionIndex].contentArray[i].id + i}
                                    id={HomeBodySectionArray[SectionIndex].contentArray[i].id}
                                    hidden={HomeBodySectionArray[SectionIndex].contentArray[i].hidden}
                                    columnNo={HomeBodySectionArray[SectionIndex].contentArray[i].columnNo}
                                    form={HomeBodySectionArray[SectionIndex].contentArray[i].form}
                                    button={HomeBodySectionArray[SectionIndex].contentArray[i].button}
                                    title={HomeBodySectionArray[SectionIndex].contentArray[i].title}
                                    link={HomeBodySectionArray[SectionIndex].contentArray[i].link}
                                    img={HomeBodySectionArray[SectionIndex].contentArray[i].img}
                                    text={HomeBodySectionArray[SectionIndex].contentArray[i].text}
                                    option={HomeBodySectionArray[SectionIndex].contentArray[i].option}
                                    imgUrl={HomeBodySectionArray[SectionIndex].contentArray[i].imgUrl}
                                    linkUrl={HomeBodySectionArray[SectionIndex].contentArray[i].linkUrl}
                                />
                            )
                            }
                        )}
                    </div>
                </div>
            );     
        } else {
            return (
                null
            );     
        }
    } else {
        return(
            null
        )
    }
}
 
export default HomeBodySection;