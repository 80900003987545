import React from 'react';
import './BottomBar.css';
import { Link } from "react-router-dom";


const BottomBarLink = ({id, textORimg, text, img, link, hidden}) => {
    if (!hidden) {
        if (textORimg === 'text') {
            return (
               <Link className="BottomBarLink" to={link}>{text}</Link>
            );     
        } else if (textORimg === 'img') {
            return (
                <Link to={link}><img className='BottomBarLinkIcon' src={img} alt={id}></img></Link>
            );     
        }
    } else {
        return(
            null
        )
    }
}
 
export default BottomBarLink;