import dillLogo from '../assets/Logo.svg';
import dLogo from '../assets/dLogo.svg';
import dLogoMedium from '../assets/dLogoMedium.svg';
import dLogoMediumLight from '../assets/dLogoMediumLight.svg';
import dLogoSmall from '../assets/dLogoSmall.svg';
import noise from '../assets/noise.svg';
import noise9 from '../assets/noise9.svg';
import noise2 from '../assets/noise2.png';
import noise5 from '../assets/noise5.png';
import noise6 from '../assets/noise6.png';
import ballCanyon from '../assets/ballCanyon.webp';
import darkClouds from '../assets/darkClouds.jpeg';
import galaxy from '../assets/galaxy.jpg';
import glass from '../assets/glass.jpeg';
import glitch from '../assets/glitch.jpeg';
import grunge from '../assets/grunge.webp';
import filmGrain from '../assets/filmGrain.svg';
import waterRipple from '../assets/waterRipple.jpeg';

export const BkgdStyleArray = [
    {
        backgroundColor: '#F3DAD0',
        backgroundImage: `repeating-linear-gradient(56deg, #F3DAD0aa -33%,#FCF9FAdd 74%,#F9F3F3aa 33%,#FCF9FAdd 24%, #F3DAD0 130%), url(${noise9}), url(${dLogoSmall})`,
        backgroundPosition: "center bottom",
        backgroundRepeat: "repeat",
        backgroundSize: "auto",
        backgroundBlendMode: "normal"
    },
    {
        backgroundColor: '#F3DAD0',
        backgroundImage: `repeating-radial-gradient(circle, #F3DAD099, #D1FFFC77 24%, #F3DAD0bb 10%), url(${noise}),  url(${dillLogo}), url(${filmGrain})`,
        backgroundPosition: "center",
        backgroundRepeat: "repeat",
        backgroundSize: "contain",
        backgroundBlendMode: "normal"
    },
    {
        backgroundColor: '#F3DAD0',
        backgroundImage: `url(${noise9}), repeating-radial-gradient(circle, #F9F3F3cc, #F3DAD099 0.1%,#F9F3F3cc 74%,#F3DAD099 33%,#F9F3F3cc 24%), url(${dLogoMediumLight}), repeating-linear-gradient(#F3DAD0ee, #F3DAD0ee), url(${ballCanyon})`,
        backgroundPosition: "center center",
        backgroundRepeat: "repeat-y",
        backgroundSize: "cover, cover, contain, cover, cover",
        backgroundBlendMode: "normal"
    },
    {
        backgroundColor: '#F3DAD0',
        backgroundImage: `repeating-linear-gradient(169deg,#F9F3F3ee, #B4D8FBdd 9%,#F9F3F3ee 74%,#B4D8FBdd 33%,#F9F3F3cc 24%, #F9F3F3cc 10%), url(${dLogoMediumLight}), repeating-linear-gradient(#F3DAD044, #B4D8FB77), url(${waterRipple})`,
        backgroundPosition: "10% 25%",
        backgroundRepeat: "repeat",
        backgroundSize: "auto",
        backgroundBlendMode: "normal"
    },
    {
        backgroundColor: '#F3DAD0',
        backgroundImage: `url(${noise9}), repeating-radial-gradient(circle, #08268B88 50%,#4E6EDE 74%,#1E867E 33%,#237BBD33 24%), url(${dLogo}), url(${dLogoMedium}), url(${dLogoSmall})`,
        backgroundPosition: "center",
        backgroundRepeat: "repeat",
        backgroundSize: "auto",
        backgroundBlendMode: "normal"
    },
    {
        backgroundColor: '#F3DAD0',
        backgroundImage: `repeating-linear-gradient(171deg,#D47055, #08268B88 9%,#D47055 74%,#08268B88 33%,#4E6EDE 24%, #589B4C 130%), url(${dLogo})`,
        backgroundPosition: "10% 25%",
        backgroundRepeat: "repeat",
        backgroundSize: "auto",
        backgroundBlendMode: "overlay"
    },
    {
        backgroundColor: '#F3DAD0',
        backgroundImage: `repeating-radial-gradient(circle, #4E6EDEbb, #F9F3F399 0.1%,#4E6EDEbb 74%,#1E867Ebb 33%,#4E6EDEbb 24%, #D47055bb 130%), url(${dillLogo})`,
        backgroundPosition: "center",
        backgroundRepeat: "repeat-y",
        backgroundSize: "cover, contain",
        backgroundBlendMode: "normal"
    },
    {
        backgroundColor: '#F3DAD0',
        backgroundImage: `repeating-linear-gradient(99deg,#F3DAD0, #08268B88 -33%,#D47055 74%,#08268B88 33%,#1E867E 24%, #589B4C 130%), url(${dLogoMedium}), url(${noise5})`,
        backgroundPosition: "10% 25%",
        backgroundRepeat: "repeat",
        backgroundSize: "cover",
        backgroundBlendMode: "overlay"
    },
    {
        backgroundColor: '#F3DAD0',
        backgroundImage: `repeating-linear-gradient(85deg, #F3DAD0, #F3DAD055 4%, #F3DAD0dd 9%, #F3DAD0dd 17%, #F3DAD0 49%, #F3DAD0dd 56%, #F3DAD055 69%, #F3DAD0), conic-gradient(#F9F3F3bb, #F3DAD0cc, #F3DAD0cc, #F9F3F3bb), url(${noise6}), url(${dLogoMedium}), url(${glitch})`,
        backgroundPosition: "center bottom",
        backgroundRepeat: "repeat",
        backgroundSize: "auto",
        backgroundBlendMode: "normal"
    },
    {
        backgroundColor: '#F3DAD0',
        backgroundImage: `repeating-radial-gradient(circle, #FBF3FC44, #FBF3FCcc 49%,#FBF3FCee, #E2EDE077 74%), repeating-linear-gradient(100deg,#F3DAD0, #F3DAD088 -33%,#FBF3FCbb 74%,#F9F3F388 33%,#F3DAD0 94%, #F3DAD0 130%), url(${dLogo}), url(${darkClouds})`, 
        backgroundPosition: "left center, left center, center center, left center",
        backgroundRepeat: "repeat-x, repeat-x, space, repeat-x",
        backgroundSize: "cover, cover, contain, cover",
        backgroundBlendMode: "normal"
    },
    {
        backgroundColor: '#F3DAD0',
        backgroundImage: `url(${noise9}), repeating-linear-gradient(159deg, #FBF3FC, #FBF3FC88 9%, #FBF3FC 51%, #E2EDE0dd 65%,#E2EDE0cc 55%, #FBF3FCdd 105%), url(${dLogo}), repeating-linear-gradient(#F3DAD088, #F3DAD099), url(${galaxy})`,
        backgroundPosition: "10% 25%",
        backgroundRepeat: "repeat",
        backgroundSize: "contain",
        backgroundBlendMode: "normal"
    },
    {
        backgroundColor: '#F3DAD0',
        backgroundImage: `repeating-linear-gradient(56deg, #F3DAD0bb, #F3DAD0 9%, #D3E5F2 74%,#F3DAD0bb 33%, #D3E5F2 24%, #D3E5F2 130%), repeating-linear-gradient(169deg,#FBF3FC, #F3DAD088 9%,#FBF3FC 74%,#F3DAD088 33%,#D3E5F2 24%, #D3E5F2 130%), url(${dLogoSmall}), url(${noise2})`,
        backgroundPosition: "center bottom",
        backgroundRepeat: "repeat",
        backgroundSize: "auto",
        backgroundBlendMode: "normal"
    },
    {
        backgroundColor: '#F3DAD0',
        backgroundImage: `repeating-radial-gradient(circle, #237BBD, #237BBD88 0.1%,#237BBD 74%,#1E867E 33%,#237BBD 24%, #E1301D 130%), url(${dLogoMediumLight}), url(${grunge}) `,
        backgroundPosition: "center",
        backgroundRepeat: "repeat-y, repeat-x, repeat-y",
        backgroundSize: "cover, contain, cover",
        backgroundBlendMode: "normal"
    },
    {
        backgroundColor: '#F3DAD0',
        backgroundImage: `url(${noise9}), repeating-linear-gradient(192deg, #B0BBD3, #F3DAD0dd 44%, #237BBDee 64%, #237BBDdd 74%, #237BBD), url(${waterRipple}), url(${dLogoMedium})`,
        backgroundPosition: "10% 25%",
        backgroundRepeat: "repeat",
        backgroundSize: "auto",
        backgroundBlendMode: "normal"
    },
    {
        backgroundColor: '#F3DAD0',
        backgroundImage: `repeating-radial-gradient(circle, #FCF9FA, #FCF9FA 1%, #FCF9FA44 3%,#FCF9FA01), conic-gradient(#FCF9FAcc, #FCF9FAaa, #FCF9FA44, #FCF9FA77, #FCF9FAcc, #FCF9FAdd, #FCF9FAdd, #FCF9FAdd, #FCF9FAcc), url(${dLogoMediumLight}), url(${noise}), url(${glass})`,
        backgroundPosition: "10% 25%",
        backgroundRepeat: "repeat",
        backgroundSize: "auto",
        backgroundBlendMode: "normal"
    }
];

