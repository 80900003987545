import dillLogo from '../assets/Logo.svg';
import mobMenu from '../assets/MobMenu.svg';



export const TopBarArray = [
    {
        id: 'home',
        hidden: 0,
        dropdown: 0,
        web: 1,
        mobile: 1,
        app: 0,
        img: 1,
        text: 'NA',
        imgUrl: dillLogo,
        linkUrl: '/'
    },
    {
        id: 'dillGuestApp',
        hidden: 0,
        dropdown: 0,
        web: 1,
        mobile: 0,
        app: 1,
        img: 0,
        text: 'dill Guest',
        imgUrl: 'NA',
        linkUrl: 'dillGuest'
    },
    {
        id: 'dillManagerApp',
        hidden: 0,
        dropdown: 0,
        web: 1,
        mobile: 0,
        app: 1,
        img: 0,
        text: 'dill Manager',
        imgUrl: 'NA',
        linkUrl: 'dillManager'
    },
    {
        id: 'dillServerApp',
        hidden: 0,
        dropdown: 0,
        web: 1,
        mobile: 0,
        app: 1,
        img: 0,
        text: 'dill Server',
        imgUrl: 'NA',
        linkUrl: 'dillServer'
    },
    {
        id: 'about',
        hidden: 0,
        dropdown: 0,
        web: 1,
        mobile: 0,
        app: 0,
        img: 0,
        text: 'About dill',
        imgUrl: 'NA',
        linkUrl: 'about'
    },
    {
        id: 'support',
        hidden: 0,
        dropdown: 0,
        web: 1,
        mobile: 0,
        app: 0,
        img: 0,
        text: 'Support',
        imgUrl: 'NA',
        linkUrl: 'support'
    },
    {
        id: 'investors',
        hidden: 0,
        dropdown: 0,
        web: 1,
        mobile: 0,
        app: 0,
        img: 0,
        text: 'Investors',
        imgUrl: 'NA',
        linkUrl: 'investors'
    },
    {
        id: 'partners',
        hidden: 0,
        dropdown: 0,
        web: 1,
        mobile: 0,
        app: 0,
        img: 0,
        text: 'Partners',
        imgUrl: 'NA',
        linkUrl: 'partners'
    },
    {
        id: 'bookVisit',
        hidden: 0,
        dropdown: 0,
        web: 1,
        mobile: 0,
        app: 0,
        img: 0,
        text: 'Book Visit',
        imgUrl: 'NA',
        linkUrl: 'bookVisit'
    },
    {
        id: 'apps',
        hidden: 0,
        dropdown: 1,
        web: 0,
        mobile: 1,
        app: 0,
        img: 0,
        text: 'dill Apps',
        imgUrl: './img.svg',
        linkUrl: 'NA'
    },
    {
        id: 'menu',
        hidden: 0,
        dropdown: 1,
        web: 0,
        mobile: 1,
        app: 0,
        img: 1,
        text: 'NA',
        imgUrl: mobMenu,
        linkUrl: 'NA'
    }
];