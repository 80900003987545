import React from 'react';
import './BottomBar.css';
import BottomBarLink from './BottomBarLink';

const BottomBarColumn = ({ BottomBarArray, columnIndex }) => {
    return ( 
        <div className='BottomBarColumn'>
            {BottomBarArray[columnIndex].map((id, i) => {
                return (
                    <BottomBarLink
                        key={BottomBarArray[columnIndex][i].id+ i}
                        id={BottomBarArray[columnIndex][i].id}
                        textORimg={BottomBarArray[columnIndex][i].textORimg}
                        text={BottomBarArray[columnIndex][i].text}
                        img={BottomBarArray[columnIndex][i].img}
                        link={BottomBarArray[columnIndex][i].link}
                        hidden={BottomBarArray[columnIndex][i].hidden}
                    />
                )
            })}
        </div>
    )
};
 
export default BottomBarColumn;