import appMap from '../assets/AppMap.svg';
import AppleAppStoreLogo from '../assets/AppleAppStore.png';
import GooglePlayStoreLogo from '../assets/GooglePlayStore.png';
import BkGrndImg1 from '../assets/BkGrndImg1.svg';

export const HomeBodySectionArray = [
    {
        id: 'main',
        leftFullRight: 'full',
        title: 'dill',
        bkgrndImg: BkGrndImg1,
        hidden: 0,
        contentArray: [
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 1,
                link: 0,
                img: 0,
                text: 'dill, a fresh take at the dining experience',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 1,
                link: 0,
                img: 0,
                text: 'dill Apps:',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 1,
                link: 1,
                img: 0,
                text: 'dill Guest App',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'DillGuest'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 1,
                link: 1,
                img: 0,
                text: 'dill Manager App',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'DillManager'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 1,
                link: 1,
                img: 0,
                text: 'dill Server App',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'DillServer'
            },
            {
                id: 't1',
                hidden: [0, 1],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [1, 0],
                link: [0, 0],
                img: [0, 1],
                text: ['Coming Soon:', 'NA'],
                option: [0, 1],
                imgUrl: ['NA', AppleAppStoreLogo],
                linkUrl: ['NA', 'NA']
            },
            {
                id: 't1',
                hidden: [0, 1],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [0, 0],
                link: [0, 0],
                img: [0, 1],
                text: ['dill Chef App', 'NA'],
                option: [1, 1],
                imgUrl: ['NA', AppleAppStoreLogo],
                linkUrl: ['NA', 'NA']
            },
            {
                id: 't1',
                hidden: [0, 0],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [0, 0],
                link: [0, 0],
                img: [0, 1],
                text: ['dill Supplier App', 'NA'],
                option: [1, 1],
                imgUrl: ['NA', AppleAppStoreLogo],
                linkUrl: ['NA', 'NA']
            },
            {
                id: 't1',
                hidden: [0, 1],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [0, 0],
                link: [0, 0],
                img: [0, 1],
                text: ['dill POS App', 'NA'],
                option: [1, 1],
                imgUrl: ['NA', GooglePlayStoreLogo],
                linkUrl: ['NA', 'NA']
            },
            {
                id: 't1',
                hidden: [0, 0],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [0, 0],
                link: [0, 0],
                img: [0, 1],
                text: ['dill Kitchen App', 'NA'],
                option: [1, 1],
                imgUrl: ['NA', GooglePlayStoreLogo],
                linkUrl: ['NA', 'NA']
            }
        ]
    },
    {
        id: 'guest',
        leftFullRight: 'left',
        title: 'dill Guest App',
        bkgrndImg: './logo.svg',
        hidden: 0,
        contentArray: [
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Own your data',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Your feedback is valuable',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Improve and influence your favourite restaurants',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Earn dillars while helping your local restaurants to perfection',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Spend your earned dillars at your favourite restaurants',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            }
        ]
    },
    {
        id: 'manager1',
        leftFullRight: 'right',
        title: 'dill Restaurant Manager App',
        bkgrndImg: './logo.svg',
        hidden: 0,
        contentArray: [
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Know exactly how your valuable customers feel about every individual dish',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: '... and every other single aspect of their dining experience',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'No need to search for answers in paragraphs of reviews',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Distilled actionable insights derived using cutting-edge data science and behavioural economics',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            }
        ]
    },
    {
        id: 'manager2',
        leftFullRight: 'full',
        title: 'dill Restaurant Management',
        bkgrndImg: './logo.svg',
        hidden: 0,
        contentArray: [
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'The heart of the dill experience',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'At dill we classify restaurant manager’s tasks into 3 categories: boring, annoying, and fun',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'We will take care of your boring and annoying tasks',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'So you can focus on the fun',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'All of the reasons you got into being a restaurateur in the first place',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: [0, 0],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [0, 0],
                link: [0, 0],
                img: [0, 0],
                text: ['Sample new ingredients', 'Organise events'],
                option: [1, 1],
                imgUrl: ['NA', 'NA'],
                linkUrl: ['NA', 'NA']
            },
            {
                id: 't1',
                hidden: [0, 0],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [0, 0],
                link: [0, 0],
                img: [0, 0],
                text: ['Invent new dishes', 'Expand your network of musicians and performers'],
                option: [1, 1],
                imgUrl: ['NA', 'NA'],
                linkUrl: ['NA', 'NA']
            },
            {
                id: 't1',
                hidden: [0, 0],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [0, 0],
                link: [0, 0],
                img: [0, 0],
                text: ['Bring in guest chefs', 'Curate the sounds of your restaurant'],
                option: [1, 1],
                imgUrl: ['NA', 'NA'],
                linkUrl: ['NA', 'NA']
            },
            {
                id: 't1',
                hidden: [0, 0],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [0, 0],
                link: [0, 0],
                img: [0, 0],
                text: ['Curate the artwork of your restaurant', 'Reach new customers'],
                option: [1, 1],
                imgUrl: ['NA', 'NA'],
                linkUrl: ['NA', 'NA']
            },
            {
                id: 't1',
                hidden: [0, 0],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [0, 0],
                link: [0, 0],
                img: [0, 0],
                text: ['Plan renovations', 'Plan your expansion'],
                option: [1, 1],
                imgUrl: ['NA', 'NA'],
                linkUrl: ['NA', 'NA']
            }
        ]
    },
    {
        id: 'manager3',
        leftFullRight: 'full',
        title: 'dill Restaurant Management Software',
        bkgrndImg: './logo.svg',
        hidden: 0,
        contentArray: [
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'The heart of the dill experience',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 1,
                text: 'List of dill Apps',
                option: 1,
                imgUrl: appMap,
                linkUrl: 'NA'
            }
        ]
    },
    {
        id: 'free',
        leftFullRight: 'left',
        title: 'dill',
        bkgrndImg: './logo.svg',
        hidden: 0,
        contentArray: [
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Free',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: '< 1 min easy installation',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Compatible with your current set up',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Compatible with all POS systems',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'No matter what your set up is, ancient or cutting-edge',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Or even if you don’t have one at all',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'No need for any changes to your setup',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            }
        ]
    },
    {
        id: 'action',
        leftFullRight: 'right',
        title: ' ',
        bkgrndImg: './logo.svg',
        hidden: 0,
        contentArray: [
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Download the app now and explore what dill can unlock for you',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Play pretend restaurant manager with a dummy restaurant in the app',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 1,
                img: 0,
                text: 'dill Restaurant Manager App',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'DillManager'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'See what dill can unlock for you, your customers, your staff, your restaurant',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: [0, 0],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [0, 0],
                link: [0, 1],
                img: [0, 0],
                text: ['and get dill', 'Book Now'],
                option: [0, 0],
                imgUrl: ['NA', 'NA'],
                linkUrl: ['NA', 'bookVisit']
            }
        ]
    },
    {
        id: 'Server',
        leftFullRight: 'left',
        title: 'dill Server App',
        bkgrndImg: './logo.svg',
        hidden: 0,
        contentArray: [
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Gain the freedom to control your own time',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Get rewarded for your performance',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Access intelligence that can help you become the best version of your work-self',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Let tech make your work life a lot easier',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'So you can focus on the fun, human parts of the job',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            }
        ]
    },
    {
        id: 'graphics',
        leftFullRight: 'right',
        title: ' ',
        bkgrndImg: './logo.svg',
        hidden: 0,
        contentArray: [
            {
                id: 't1',
                hidden: 1,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 1,
                link: 0,
                img: 0,
                text: '.',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            }
        ]
    },
    {
        id: 'chef',
        leftFullRight: 'left',
        title: 'Back of House Developments',
        bkgrndImg: './logo.svg',
        hidden: 0,
        contentArray: [
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 1,
                link: 0,
                img: 0,
                text: 'dill Chef App',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: '...coming very very soon',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 1,
                link: 0,
                img: 0,
                text: 'dill Supplier App',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: '...coming very very soon',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            }
        ]
    },
    {
        id: 'stationary',
        leftFullRight: 'right',
        title: 'Restaurant Stationary Fixtures',
        bkgrndImg: './logo.svg',
        hidden: 0,
        contentArray: [
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 1,
                link: 0,
                img: 0,
                text: 'dill Kitchen Display App',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: '...coming very very soon',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 1,
                link: 0,
                img: 0,
                text: 'dill Stationary POS App',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: '...coming very very soon',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            }
        ]
    },
    {
        id: 'testimonials',
        leftFullRight: 'full',
        title: 'Testimonials',
        bkgrndImg: './logo.svg',
        hidden: 1,
        contentArray: [
            {
                id: 't1',
                hidden: [0, 1],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [1, 0],
                link: [0, 0],
                img: [0, 0],
                text: ['Hassan F.', ' '],
                option: [0, 0],
                imgUrl: ['NA', 'NA'],
                linkUrl: ['NA', 'NA']
            },
            {
                id: 't1',
                hidden: [0, 0],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [0, 1],
                link: [0, 0],
                img: [0, 0],
                text: ['I love dill', 'Mark T.'],
                option: [0, 0],
                imgUrl: ['NA', 'NA'],
                linkUrl: ['NA', 'NA']
            },
            {
                id: 't1',
                hidden: [1, 0],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [0, 0],
                link: [0, 0],
                img: [0, 0],
                text: [' ', 'My life is so much simpler now'],
                option: [0, 0],
                imgUrl: ['NA', 'NA'],
                linkUrl: ['NA', 'NA']
            },
            {
                id: 't1',
                hidden: [0, 1],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [1, 0],
                link: [0, 0],
                img: [0, 0],
                text: ['Hassan F.', ' '],
                option: [0, 0],
                imgUrl: ['NA', 'NA'],
                linkUrl: ['NA', 'NA']
            },
            {
                id: 't1',
                hidden: [0, 0],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [0, 1],
                link: [0, 0],
                img: [0, 0],
                text: ['I love dill', 'Mark T.'],
                option: [0, 0],
                imgUrl: ['NA', 'NA'],
                linkUrl: ['NA', 'NA']
            },
            {
                id: 't1',
                hidden: [1, 0],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [0, 0],
                link: [0, 0],
                img: [0, 0],
                text: [' ', 'My life is so much simpler now'],
                option: [0, 0],
                imgUrl: ['NA', 'NA'],
                linkUrl: ['NA', 'NA']
            }
        ]
    },
    {
        id: 'partners',
        leftFullRight: 'full',
        title: 'Partners',
        bkgrndImg: './logo.svg',
        hidden: 1,
        contentArray: [
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 1,
                link: 0,
                img: 0,
                text: 'Chain Restaurants',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: [0, 0],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [0, 0],
                link: [0, 0],
                img: [0, 0],
                text: ['Plan renovations', 'Plan your expansion'],
                option: [0, 0],
                imgUrl: ['NA', 'NA'],
                linkUrl: ['NA', 'NA']
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 1,
                link: 0,
                img: 0,
                text: 'Food Tech Partners',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: [0, 0],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [0, 0],
                link: [0, 0],
                img: [0, 0],
                text: ['Plan renovations', 'Plan your expansion'],
                option: [0, 0],
                imgUrl: ['NA', 'NA'],
                linkUrl: ['NA', 'NA']
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 1,
                link: 0,
                img: 0,
                text: 'Investment Firms',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: [0, 0],
                columnNo: 2,
                form: [0, 0],
                button: [0, 0],
                title: [0, 0],
                link: [0, 0],
                img: [0, 0],
                text: ['Plan renovations', 'Plan your expansion'],
                option: [0, 0],
                imgUrl: ['NA', 'NA'],
                linkUrl: ['NA', 'NA']
            }
        ]
    },
    {
        id: 'linktree',
        leftFullRight: 'left',
        title: 'dill Link tree',
        bkgrndImg: './logo.svg',
        hidden: 0,
        contentArray: [
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 1,
                link: 2,
                img: 0,
                text: 'Crunchbase',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'https://www.crunchbase.com/organization/dill-c659'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 1,
                link: 2,
                img: 0,
                text: 'AngelList',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'https://angel.co/company/dill-2'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 1,
                link: 2,
                img: 0,
                text: 'LinkedIn',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'https://www.linkedin.com/company/dillar'
            }
        ]
    },
    {
        id: 'news',
        leftFullRight: 'right',
        title: 'News, Events, and Highlights',
        bkgrndImg: './logo.svg',
        hidden: 0,
        contentArray: [
            {
                id: 't1',
                hidden: 1,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Catch our virtual conference on the changing shape of restaurant tech June 17',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 1,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Proud to be highlighted in forbes.com as hottest thing in the kitchen after the oven',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 1,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 2,
                img: 0,
                text: 'https://forbes.com/kitchen- hot',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'https://forbes.com/kitchen- hot'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Scheduled events to be announced soon. Please check soon.',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 0,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'Get in touch via the contact form and we will keep you updated on our growth.',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            },
            {
                id: 't1',
                hidden: 1,
                columnNo: 1,
                form: 0,
                button: 0,
                title: 0,
                link: 0,
                img: 0,
                text: 'the dill Guest App has been published on Apple App Store and Google Play Store!',
                option: 0,
                imgUrl:'NA',
                linkUrl: 'NA'
            }
        ]
    }
]