import React from 'react';
import './HomeBodySection.css';
import { Link } from "react-router-dom";

const HBSContentRowItem = ({ id, hidden, columnNo, form, button, title, link, img, text, option, imgUrl, linkUrl }) => {
    if (!hidden){
        if (title) {
            if (link) {
                if (link === 1) {
                    return (
                        <Link to={linkUrl} className="HBSContentRowTitle">{text}</Link>
                    )
                } else if (link > 1) {
                    return (
                        <a href={linkUrl} className="HBSContentRowTitle">{text}</a>
                    )
                } else {
                    return null
                }
            } else {
                return (
                    <div className="HBSContentRowTitle">{text}</div>
                )
            }
        } else if (img) {
            if ((option)) {
                if (link) {
                    if (link === 1) {
                        return (
                            <Link to={linkUrl} className='HBSContentRowImg'><img className='HBSContentRowImgRight' src={imgUrl} alt={id} /></Link>
                        )
                    } else if (link > 1) {
                        return (
                            <a href={linkUrl} className='HBSContentRowImg'><img className='HBSContentRowImgRight' src={imgUrl} alt={id} /></a>
                        )
                    } else {
                        return null
                    }
                } else {
                    if (columnNo === 2) {
                        return (
                            <div className='HBSContentRowImg'><img className='HBSContentRowImgRight' src={imgUrl} alt={id} /></div>
                        )
                    } else {
                        return (
                            <div className='HBSContentRowImg'><img className='HBSContentImgLarge' src={imgUrl} alt={id} /></div>
                        )
                    }

                }
            } else {
                if (link) {
                    if (link === 1) {
                        return (
                            <Link to={linkUrl}><img className='HBSContentRowImg' src={imgUrl} alt={id} /></Link>
                        )
                    } else if (link > 1) {
                        return (
                            <a href={linkUrl}><img className='HBSContentRowImg' src={imgUrl} alt={id} /></a>
                        )
                    } else {
                        return null
                    }
                } else {
                    return (
                        <div><img className='HBSContentRowImg' src={imgUrl} alt={id} /></div>
                    )
                }
            }
        } else {
            if (link) {
                if (link === 1) {
                    return (
                        <Link to={linkUrl} className="HBSContentRowText">{text}</Link>
                    )
                } else if (link > 1) {
                    return (
                        <a href={linkUrl} className="HBSContentRowText">{text}</a>
                    )
                } else {
                    return null
                }
            } else {
                if (option){
                    return (
                        <div className="HBSContentRowText HBSContentRowTextSmall">{text}</div>
                    )
                } else {
                    return (
                        <div className="HBSContentRowText">{text}</div>
                    )
                }
            }
        }
    } else {
        return(
            null
        )
    }
}
 
export default HBSContentRowItem;