import React, { useState } from 'react';
import './TopBar.css';
import { Link } from "react-router-dom";
import DropdownMenu from './DropdownMenu';

function TopBarItem ({id, hidden, dropdown, web, mobile, app, img, text, imgUrl, linkUrl, dropdownArray}) {

    const [isMobile, setIsMobile] = useState(0)
    const [hoverApps, setHoverApps] = useState(0)
    const [hoverMenu, setHoverMenu] = useState(0)

    function updateIsMobile(){
        if (isMobile) {
            if (window.innerWidth > 808) {
                setIsMobile(0);
            }
        } else {
            if (window.innerWidth < 808) {
                setIsMobile(1);
            }
        }
    }

    let currentPage = window.location.href;

    function dropDownHide(itemId){
        if ((itemId === 'apps') && (hoverApps)) {
            setHoverApps(0);
        }
        if ((itemId === 'menu') && (hoverMenu)) {
            setHoverMenu(0)
        }
    }

    function dropDownDisplay(itemId){
        if ((itemId === 'apps') && (!hoverApps)) {
            setHoverApps(1);
        }
        if ((itemId === 'menu') && (!hoverMenu)) {
            setHoverMenu(1)
        }
        if (currentPage !== window.location.href) {
            currentPage = window.location.href;
            dropDownHide('apps')
            dropDownHide('menu')
        }
    }

    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);

    if (!hidden) {
        if (!isMobile) {
            if (web) {
                if (img) {
                    if (dropdown){
                        return (
                            <div onMouseEnter={() => dropDownDisplay(id)} onMouseLeave={() => dropDownHide(id)} onClick={() => dropDownDisplay(id)} className="TopBarItem">
                                <button><img className='TopBarItemIcon' src={imgUrl} alt={id} /></button>
                                {(() => {
                                    if ((hoverApps && (id='apps')) || (hoverMenu && (id='menu'))) {
                                        return(
                                            <DropdownMenu parentId={id} dropdownArray={dropdownArray} />
                                        )
                                    } else {
                                        return null
                                    }
                                })()}
                            </div>
                            )
                    } else {
                        return (
                            <Link to={linkUrl}><img className='TopBarItemIcon' src={imgUrl} alt={id} /></Link>
                        )
                    }
                } else {
                    if (dropdown){
                        return (
                            <div onMouseEnter={() => dropDownDisplay(id)} onMouseLeave={() => dropDownHide(id)} onClick={() => dropDownDisplay(id)} className="TopBarItem">
                                <button className="TopBarItem">{text}</button>
                                {(() => {
                                    if ((hoverApps && (id='apps')) || (hoverMenu && (id='menu'))) {
                                        return(
                                            <DropdownMenu parentId={id} dropdownArray={dropdownArray} />
                                        )
                                    } else {
                                        return null
                                    }
                                })()}
                            </div>
                        )   
                    } else {
                        return (
                            <Link to={linkUrl} className="TopBarItem">{text}</Link>
                        )
                    }
                }
            } else {
                return null
            }
        } else {
            if (mobile) {
                if (img) {
                    if (dropdown){
                        return (
                            <div onMouseEnter={() => dropDownDisplay(id)} onMouseLeave={() => dropDownHide(id)} onClick={() => dropDownDisplay(id)} className="TopBarItem">
                                <button><img className='TopBarItemIcon' src={imgUrl} alt={id} /></button>
                                {(() => {
                                    if ((hoverApps && (id='apps')) || (hoverMenu && (id='menu'))) {
                                        return(
                                            <DropdownMenu parentId={id} dropdownArray={dropdownArray} />
                                        )
                                    } else {
                                        return null
                                    }
                                })()}
                            </div>
                        )
                    } else {
                        return (
                            <Link to={linkUrl}><img className='TopBarItemIcon' src={imgUrl} alt={id} /></Link>
                        )
                    }
                } else {
                    if (dropdown){
                        return (
                            <div onMouseEnter={() => dropDownDisplay(id)} onMouseLeave={() => dropDownHide(id)} onClick={() => dropDownDisplay(id)} className="TopBarItem">
                                <button className="TopBarItem">{text}</button>
                                {(() => {
                                    if ((hoverApps && (id='apps')) || (hoverMenu && (id='menu'))) {
                                        return(
                                            <DropdownMenu parentId={id} dropdownArray={dropdownArray} />
                                        )
                                    } else {
                                        return null
                                    }
                                })()}
                            </div>
                        )
                    } else {
                        return (
                            <Link to={linkUrl} className="TopBarItem">{text}</Link>
                        )
                    }
                }
            } else {
                return null
            }
        }
    } else {
        return(
            null
        )
    }
}
 
export default TopBarItem;