import React, { useState } from 'react';
import './Page.css';
import { useParams } from "react-router-dom";
import PageRow from './PageRow';
import ErrorPage from '../ErrorPage'
import { app } from '../firebase';
import { getDatabase, ref, update } from "firebase/database"

const Page = ({ PagesContentArray }) => {

    let { page } = useParams();

    const [isMobile, setIsMobile] = useState(0);
    const [submitting, setSubmitting] = useState(0);

    function updateIsMobile(){
        if (isMobile) {
            if (window.innerWidth > 808) {
                setIsMobile(0);
            }
        } else {
            if (window.innerWidth < 808) {
                setIsMobile(1);
            }
        }
    }

    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);

    function gatherMessage () {
        if (!app) { return null }
        var formInputs = document.getElementsByTagName('input');
        var formTextAreas = document.getElementsByTagName('textarea');
        var formInputsArr = Array.from(formInputs);
        var formTextAreasArr = Array.from(formTextAreas);
        var formArr = formInputsArr.concat(formTextAreasArr);
        var submittedForm = {}
        formArr.map((i) => (
            submittedForm[i.id] = i.value
        ));
        return submittedForm
    }
    
    function sendToFirebase (submittedForm) {
        const db = getDatabase();
        var dbLoc = ref(db, PagesContentArray[`${page}`].id);
        var currentdate = new Date();
        var entryId = ("0" + currentdate.getFullYear()).slice(-4) + ("0" + (currentdate.getMonth()+1)).slice(-2) + ("0" + currentdate.getDate()).slice(-2) + ("0" + currentdate.getHours()).slice(-2) + ("0" + currentdate.getMinutes()).slice(-2) + ("0" + currentdate.getSeconds()).slice(-2) + parseInt(performance.now()).toString().slice(-3)
        var messagePacket = {[entryId]: submittedForm}
        update(dbLoc, messagePacket).then(null)
    }
    
    function submitContactForm(event) {
        event.preventDefault();
        sendToFirebase(gatherMessage());
        setSubmitting(1);
        setTimeout(() => { setSubmitting(0) }, 2620);
        setTimeout(() => { document.getElementById('dillForm').reset() }, 1618);
    }

    if (PagesContentArray[`${page}`] === undefined){
        return (
            <ErrorPage/>
        )
    } else {
        if (PagesContentArray[`${page}`].appPage) {
            return (
                <div className='Page'>
                    <div className='PageTitle'>
                        {PagesContentArray[`${page}`].title}
                    </div>
                    <div className='PageContentColumn'>
                        {PagesContentArray[`${page}`].contentArray.map((id, i) => {
                            return (
                                <PageRow
                                    key={PagesContentArray[`${page}`].contentArray[i].id + i}
                                    id={PagesContentArray[`${page}`].contentArray[i].id}
                                    parentAppPage={PagesContentArray[`${page}`].appPage}
                                    hidden={PagesContentArray[`${page}`].contentArray[i].hidden}
                                    columnNo={PagesContentArray[`${page}`].contentArray[i].columnNo}
                                    form={PagesContentArray[`${page}`].contentArray[i].form}
                                    button={PagesContentArray[`${page}`].contentArray[i].button}
                                    title={PagesContentArray[`${page}`].contentArray[i].title}
                                    link={PagesContentArray[`${page}`].contentArray[i].link}
                                    img={PagesContentArray[`${page}`].contentArray[i].img}
                                    text={PagesContentArray[`${page}`].contentArray[i].text}
                                    option={PagesContentArray[`${page}`].contentArray[i].option}
                                    imgUrl={PagesContentArray[`${page}`].contentArray[i].imgUrl}
                                    linkUrl={PagesContentArray[`${page}`].contentArray[i].linkUrl}
                                />                    
                            )
                        })}
                    </div>
                    <div className='PageBottomStatement'>
                        Copyright © 2022 dill Tech Inc. All rights reserved.
                    </div>
                </div>
            )
        } else if (PagesContentArray[`${page}`].form) {
            return (
                <div className='Page'>
                    <div className='PageTitle'>
                        {PagesContentArray[`${page}`].title}
                    </div>
                    <div className='PageContentColumn'>                            
                        <PageRow
                            key={PagesContentArray[`${page}`].contentArray[0].id}
                            id={PagesContentArray[`${page}`].contentArray[0].id}
                            parentAppPage={PagesContentArray[`${page}`].appPage}
                            hidden={PagesContentArray[`${page}`].contentArray[0].hidden}
                            columnNo={PagesContentArray[`${page}`].contentArray[0].columnNo}
                            form={PagesContentArray[`${page}`].contentArray[0].form}
                            button={PagesContentArray[`${page}`].contentArray[0].button}
                            title={PagesContentArray[`${page}`].contentArray[0].title}
                            link={PagesContentArray[`${page}`].contentArray[0].link}
                            img={PagesContentArray[`${page}`].contentArray[0].img}
                            text={PagesContentArray[`${page}`].contentArray[0].text}
                            option={PagesContentArray[`${page}`].contentArray[0].option}
                            imgUrl={PagesContentArray[`${page}`].contentArray[0].imgUrl}
                            linkUrl={PagesContentArray[`${page}`].contentArray[0].linkUrl}
                        />                    
                    </div>
                    <form id='dillForm' onSubmit={(event) => submitContactForm(event)} className='PageContentColumn'>
                        {(() => {
                            if (isMobile){
                                return (
                                    <input id="Name" className='InputBox SpecialInputBox' type="text" placeholder="Your full name" required="" name="Name"/>
                                )
                            } else {
                                return(
                                    <label className='GenericInput'>
                                        <div className='GenericInputLabel'>
                                            Name
                                        </div>                      
                                        <input id="Name" className='InputBox GenericInputBox' type="text" placeholder="Your full name" required="" name="Name"/>
                                    </label>
                                )
                            }
                        })()}
                        {(() => {
                            if (isMobile){
                                return (
                                    <input id="Tel" className='InputBox SpecialInputBox' type="text" placeholder="Your telephone number" required="" name="tel"/>
                                )
                            } else {
                                return(
                                    <label className='GenericInput'>
                                        <div className='GenericInputLabel'>
                                            tel
                                        </div>                      
                                        <input id="Tel" className='InputBox GenericInputBox' type="text" placeholder="Your telephone number" required="" name="tel"/>
                                    </label>
                                )
                            }
                        })()}
                        {(() => {
                            if (isMobile){
                                return (
                                    <input id="Email" className='InputBox SpecialInputBox' type="text" placeholder="Your email address" required="" name="email"/>
                                )
                            } else {
                                return(
                                    <label className='GenericInput'>
                                        <div className='GenericInputLabel'>
                                            email
                                        </div>                      
                                        <input id="Email" className='InputBox GenericInputBox' type="text" placeholder="Your email address" required="" name="email"/>
                                    </label>
                                )
                            }
                        })()}
    
                        {PagesContentArray[`${page}`].contentArray.slice(1).map((id, i) => {
                            return (
                                <PageRow
                                    key={PagesContentArray[`${page}`].contentArray.slice(1)[i].id + i}
                                    id={PagesContentArray[`${page}`].contentArray.slice(1)[i].id}
                                    parentAppPage={PagesContentArray[`${page}`].appPage}
                                    hidden={PagesContentArray[`${page}`].contentArray.slice(1)[i].hidden}
                                    columnNo={PagesContentArray[`${page}`].contentArray.slice(1)[i].columnNo}
                                    form={PagesContentArray[`${page}`].contentArray.slice(1)[i].form}
                                    button={PagesContentArray[`${page}`].contentArray.slice(1)[i].button}
                                    title={PagesContentArray[`${page}`].contentArray.slice(1)[i].title}
                                    link={PagesContentArray[`${page}`].contentArray.slice(1)[i].link}
                                    img={PagesContentArray[`${page}`].contentArray.slice(1)[i].img}
                                    text={PagesContentArray[`${page}`].contentArray.slice(1)[i].text}
                                    option={PagesContentArray[`${page}`].contentArray.slice(1)[i].option}
                                    imgUrl={PagesContentArray[`${page}`].contentArray.slice(1)[i].imgUrl}
                                    linkUrl={PagesContentArray[`${page}`].contentArray.slice(1)[i].linkUrl}
                                />                    
                            )
                        })}
                        {(() => {
                            if (!submitting){
                                return (
                                    <button id="FormSend" type="submit" className='FormButton' >
                                        Send to dill
                                    </button>
                                )
                            } else {
                                return (
                                    <button id="FormSend" type="submit" className='FormButton FormButtonSubmitting' >
                                        Sent to dill!
                                    </button>
                                )
                            }
                        })()}
                    </form>
                    <div className='PageBottomStatement'>
                        Copyright © 2022 dill Tech Inc. All rights reserved.
                    </div>
                </div>
            )
        } else {
            return (
                <div className='Page'>
                    <div className='PageTitle'>
                        {PagesContentArray[`${page}`].title}
                    </div>
                    <div className='PageContentColumn'>
                        {PagesContentArray[`${page}`].contentArray.map((id, i) => {
                            return (
                                <PageRow
                                    key={PagesContentArray[`${page}`].contentArray[i].id + i}
                                    id={PagesContentArray[`${page}`].contentArray[i].id}
                                    parentAppPage={PagesContentArray[`${page}`].appPage}
                                    hidden={PagesContentArray[`${page}`].contentArray[i].hidden}
                                    columnNo={PagesContentArray[`${page}`].contentArray[i].columnNo}
                                    form={PagesContentArray[`${page}`].contentArray[i].form}
                                    button={PagesContentArray[`${page}`].contentArray[i].button}
                                    title={PagesContentArray[`${page}`].contentArray[i].title}
                                    link={PagesContentArray[`${page}`].contentArray[i].link}
                                    img={PagesContentArray[`${page}`].contentArray[i].img}
                                    text={PagesContentArray[`${page}`].contentArray[i].text}
                                    option={PagesContentArray[`${page}`].contentArray[i].option}
                                    imgUrl={PagesContentArray[`${page}`].contentArray[i].imgUrl}
                                    linkUrl={PagesContentArray[`${page}`].contentArray[i].linkUrl}
                                />                    
                            )
                        })}
                    </div>
                    <div className='PageBottomStatement'>
                        Copyright © 2022 dill Tech Inc. All rights reserved.
                    </div>
                </div>
            )
        }
    } 
}

export default Page;