import React from 'react';
import './App.css';


const ErrorPage = () => {
    return (
      <div className='Error'>
        <h1>These are not the droids you are looking for. Sorry.</h1>
        <h1>404 Error. Page not found.</h1>
      </div>
    ) 
  };
  
  export default ErrorPage;