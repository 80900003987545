import React from 'react';
import BottomBarColumn from "./BottomBarColumn";
import './BottomBar.css';

const BottomBar = ({ BottomBarArray }) => {
    return ( 
        <div className='BottomBarOuter'>
            <div className='BottomBar'>
                {BottomBarArray.map((id, i) => {
                    return (
                        <BottomBarColumn
                        key={i}
                        BottomBarArray = {BottomBarArray}
                        columnIndex = {i}
                        />                    
                    )
                    }
                )}
            </div>
            <div className='BottomBarStatement'>
                Copyright © 2022 dill Tech Inc. All rights reserved.
            </div>
        </div>
    )
}

export default BottomBar;