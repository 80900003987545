import React from 'react';
import './TopBar.css';
import { Link } from "react-router-dom";

const DropdownMenuItem = ({id, parentId, hidden, dropdown, app, img, text, imgUrl, linkUrl}) => {
    if ( (!dropdown) && (app) && (parentId === 'apps') ){
        if (!hidden) {
            if (img) {
                return (
                    <Link to={linkUrl}><img className='TopBarLinkIcon DropdownMenuRow' src={imgUrl} alt={id} /></Link>
                )    
            } else {
                return(
                    <Link to={linkUrl} className=" TopBarLink DropdownMenuRow">{text}</Link>
                )
            }
        } else {
                return null
        }
    } else if ( (!dropdown) && (!app) && (parentId !== 'apps') && (id !== 'home') ){
        if (!hidden) {
            if (img) {
                return (
                    <Link to={linkUrl}><img className='TopBarLinkIcon DropdownMenuRow' src={imgUrl} alt={id} /></Link>
                )    
            } else {
                return(
                    <Link to={linkUrl} className=" TopBarLink DropdownMenuRow">{text}</Link>
                )
            }
        } else {
                return null
        }
    } else {
        return (
            null
        )
    }
}
 
export default DropdownMenuItem;